import React, { useState } from "react";

const Map = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  
  const submitForm = async (e) => {
    e.preventDefault();
    
    try {
      const response = await fetch("https://formspree.io/f/mkgnadrz", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      
      if (response.ok) {
        setFormSubmitted(true);
        setFormData({
          name: "",
          email: "",
          message: "",  
        })
      }
    } catch (error) {
      console.error("Form submission error:", error);
    }
  };

  return (
    <>
      <section className="text-gray-600 body-font relative bg-white" id="support">
      <h1 className="text-3xl font-bold text-slate-800 justify-self-center">CONTACT US</h1>
        <div className="container px-5 py-10 mx-auto flex sm:flex-nowrap flex-wrap">
          <div className="
         w-full h-[500px] lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden p-10 flex items-end justify-start relative">
            <iframe
              width="100%"
              height="100%"
              className="absolute inset-0"
              title="map"
              src="https://www.google.com/maps/embed/v1/place?q=Kankarbargh&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
            />
          </div>
          <div className="lg:w-1/3 p-5 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
            <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">
              Feedback
            </h2>
              <>
                <p className="leading-relaxed mb-5 text-gray-600">
                  Write your feedback here.
                </p>
                <form onSubmit={submitForm}>
                  <div className="relative mb-4">
                    <label
                      htmlFor="name"
                      className="leading-7 text-sm text-gray-600"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      required
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  </div>
                  <div className="relative mb-4">
                    <label
                      htmlFor="email"
                      className="leading-7 text-sm text-gray-600"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      required
                      value={formData.email}
                      onChange={handleChange}
                      className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  </div>
                  <div className="relative mb-4">
                    <label
                      htmlFor="message"
                      className="leading-7 text-sm text-gray-600"
                    >
                      Message
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      required
                      value={formData.message}
                      onChange={handleChange}
                      className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                    />
                  </div>
                  {formSubmitted ? (
              <p className="text-slate-800 text-xl font-bold">Thanks for your feedback!</p>
            ) : (
              <button
                    type="submit"
                    className="text-white w-full bg-slate-800 border-0 py-2 px-6 focus:outline-none hover:bg-slate-700 rounded text-lg"
                  >
                    Submit
                  </button>
            )}
                  
                </form>
                <p className="text-xs text-gray-500 mt-3">
                  We will get back to you soon.
                </p>
              </>
            
          </div>
        </div>
      </section>
    </>
  );
};

export default Map;
