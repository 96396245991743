import React from "react";
import {cn} from "../lib/utils"
import Marquee from "react-fast-marquee";



const Review = () => {

  const reviews = [
    {
      name: "Shivam",
      username: "@shivpathk",
      body: "Tiffin Ghar has made my daily meals hassle-free! The food is delicious, fresh, and tastes just like home-cooked meals. Highly recommended! ⭐⭐⭐⭐⭐",
      img: "https://avatar.vercel.sh/jack",
    },
    {
      name: "Aman",
      username: "@aman",
      body: "Amazing service! The tiffins are always delivered on time, and the quality is top-notch. Perfect for busy professionals! ⭐⭐⭐⭐⭐",
      img: "https://avatar.vercel.sh/jill",
    },
    {
      name: "Rohan",
      username: "@rohan",
      body: "As a student living away from home, Tiffin Ghar has been a blessing. The variety in the menu and the homely taste make every meal delightful!  ⭐⭐⭐⭐⭐",
      img: "https://avatar.vercel.sh/jack",
    },
    {
      name: "Rahul",
      username: "@rahul",
      body: "The perfect combination of taste and hygiene! I’ve been a loyal customer for months and couldn’t be happier.! ⭐⭐⭐⭐⭐",
      img: "https://avatar.vercel.sh/jill",
    },
    {
      name: "Deepak",
      username: "@deepak",
      body: "Affordable, healthy, and tasty meals delivered straight to my doorstep. Tiffin Ghar is a lifesaver for working moms like me! ⭐⭐⭐⭐⭐",
      img: "https://avatar.vercel.sh/john",
    },
    {
      name: "Anjali",
      username: "@anjali",
      body: "Their paneer dishes are heavenly, and the rotis are always soft. The best tiffin service I’ve ever tried! ⭐⭐⭐⭐⭐",
      img: "https://avatar.vercel.sh/jane",
    },
    {
      name: "Akash",
      username: "@akash",
      body: "Tiffin Ghar has exceeded my expectations. From portion sizes to flavors, everything is just perfect. Keep up the great work!⭐⭐⭐⭐⭐",
      img: "https://avatar.vercel.sh/john",
    },
    {
      name: "Jyoti",
      username: "@jyoti",
      body: "Excellent variety and very accommodating to dietary preferences. I love how they make healthy food so tasty!  ⭐⭐⭐⭐⭐",
      img: "https://avatar.vercel.sh/jane",
    },
    {
      name: "Nikita",
      username: "@nikita",
      body: "The service is outstanding, and the food quality is consistent. Tiffin Ghar truly feels like eating at home.!  ⭐⭐⭐⭐⭐",
      img: "https://avatar.vercel.sh/jenny",
    },
    {
      name: "Priya",
      username: "@priya",
      body: "Great value for money! The portions are generous, and the food never disappoints!!   ⭐⭐⭐⭐⭐",
      img: "https://avatar.vercel.sh/james",
    },
    {
      name: "Rakesh",
      username: "@rakesh",
      body: "I appreciate their attention to cleanliness and quality. Tiffin Ghar is my go-to for wholesome, homely meals! ⭐⭐⭐⭐⭐",
      img: "https://avatar.vercel.sh/jenny",
    },
    {
      name: "Mohit",
      username: "@mohit",
      body: "Their customer support is amazing, and they always listen to feedback. I feel cared for as a customer. Thank you, Tiffin Ghar! ⭐⭐⭐⭐⭐",
      img: "https://avatar.vercel.sh/james",
    },
    {
      name: "Sandeep",
      username: "@sandeep",
      body: "I appreciate their attention to cleanliness and quality. Tiffin Ghar is my go-to for wholesome, homely meals! ⭐⭐⭐⭐⭐ ⭐⭐⭐⭐⭐",
      img: "https://avatar.vercel.sh/jenny",
    },
    {
      name: "Priya",
      username: "@priya",
      body: "Tiffin Ghar has exceeded my expectations. From portion sizes to flavors, everything is just perfect. Keep up the great work! ⭐⭐⭐⭐⭐",
      img: "https://avatar.vercel.sh/james",
    },
    {
      name: "Gauhar Talib",
      username: "@gauhartalib",
      body: "Affordable, healthy, and tasty meals delivered straight to my doorstep. Tiffin Ghar is a lifesaver for working moms like me! ⭐⭐⭐⭐⭐",
      img: "https://avatar.vercel.sh/jack",
    },
    {
      name: "Aman",
      username: "@aman",
      body: "Their paneer dishes are heavenly, and the rotis are always soft. The best tiffin service I’ve ever tried! ⭐⭐⭐⭐⭐",
      img: "https://avatar.vercel.sh/jill",
    },
  ];

  const firstRow = reviews.slice(0, reviews.length / 2);
const secondRow = reviews.slice(reviews.length / 2);

const ReviewCard = ({img,name,username,body})=>{
  return (
    <figure
      className={cn(
        "relative w-64 cursor-pointer overflow-hidden rounded-xl border p-4 m-5",
        // light styles
        "border-gray-950/[.1] bg-gray-950/[.01] hover:bg-gray-950/[.05]",
      )}
    >
      <div className="flex flex-row items-center gap-2">
        <img className="rounded-full" width="32" height="32" alt="" src={img} />
        <div className="flex flex-col">
          <figcaption className="text-sm font-medium dark:text-white">
            {name}
          </figcaption>
          <p className="text-xs font-medium dark:text-white/40">{username}</p>
        </div>
      </div>
      <blockquote className="mt-2 text-sm">{body}</blockquote>
    </figure>
  )
}


  return (
    <section className="bg-white body-font rounded border shadow-lg overflow-hidden m-5 ">
      <div className="text-2xl text-slate-800 font-bold text-center my-3">
        OUR HAPPY CUSTOMERS :)
      </div>
    <div className="relative flex h-[500px] w-full flex-col items-center justify-center overflow-hidden rounded-lg border bg-background md:shadow-xl">
      <Marquee pauseOnHover className="[--duration:10s]" >
        {firstRow.map((review) => (
          <ReviewCard key={review.username} {...review} />
        ))}
      </Marquee>
      <Marquee direction="reverse" pauseOnHover className="[--duration:10s]">
        {secondRow.map((review) => (
          <ReviewCard key={review.username} {...review} />
        ))}
      </Marquee>
      <div className="pointer-events-none absolute inset-y-0 left-0 w-1/3 bg-gradient-to-r from-white dark:from-background"></div>
      <div className="pointer-events-none absolute inset-y-0 right-0 w-1/3 bg-gradient-to-l from-white dark:from-background"></div>
    </div>
    </section>
  );
};

export default Review;
