import React, { useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material'
import ChooseUs from '../components/Home/ChooseUs'
import HeroSlider from '../components/Home/HeroSlider'
import Info from '../components/Home/Info'
import Testimonials from "../components/Testimonials"
import HomeLayout from '../layouts/Home.layout'
import FoodCarousel from '../components/Home/FoodCarousel'
import Order from '../components/Home/Order'
import ContactForm from './ContactForm'
import logo from '../components/TiffinGharLogo.png'

import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Map from '../components/Map'

function HomePage() {
  const [active, setActive] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setActive(true)
    }, 600);
  }, [])
  if (!active) {
    return (
      <div className='w-full flex items-center justify-center' style={{ height: '85vh' }}>
        <CircularProgress />
      </div>
    )
  }
  return (
    <div>
     <ContactForm/>
      <Info />
      <FoodCarousel />
      <HeroSlider />
      <Order />
      {/* Price */}
      <ChooseUs />
      <Testimonials />
      <Map/>
      <FloatingWhatsApp
        phoneNumber="+917870087169"
        accountName="Tiffin Ghar"
        allowRouting={true}
        allowEsc
        autoOpenNewTab={true}
        avatar={logo}
        statusMessage="Online"
        notification={true}
        notificationDelay={5}
        notificationSound={true}
      />
    </div>
  )
}

export default HomeLayout(HomePage)
