import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from 'react-hot-toast'

const ContactForm = () => {


    const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    "https://img.freepik.com/premium-photo/indian-hindu-veg-thali-food-platter-selective-focus_466689-35962.jpg?semt=ais_hybrid",
    "https://img.freepik.com/free-photo/ramadan-celebration-with-delicious-food_23-2151404398.jpg?semt=ais_hybrid",
    "https://img.freepik.com/free-photo/delicious-indian-food-tray_23-2148723505.jpg?semt=ais_hybrid",
    "https://img.freepik.com/free-photo/delicious-food-table_23-2150857814.jpg?semt=ais_hybrid",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // Change image every 5 seconds

    return () => {
      clearInterval(interval);
    };
  }, [images.length]);


  const handleFormSubmit = async(e) => {
    e.preventDefault();
    const name = document.getElementById("first_name").value;
    const numberFrom = document.getElementById("phone").value;
    if(name===""){
      toast.error("Please Enter the name")
      return
    }
    if(numberFrom===""){
      toast.error("Please Enter the Phone Number")
      return
    }
    if(numberFrom.length<10){
      toast.error("Please Enter a Valid Phone Number")
      return
    }
    const message = `TiffinGhar query from ${name} with phone number ${numberFrom}`;
    const numberTo = "+919155307250";
    try {
      const res = await axios.post(
        "http://localhost:4000/api/messages",
        {
          "to": numberTo,
          "body":message,
        }
      );
      if(res.status===200){
        toast.success("Wait for just 15 minutes");
        document.getElementById("first_name").value = "";
      document.getElementById("phone").value = "";
      }else{
        console.log(res.error);
        toast.error(res.error);
      }
      
    } catch (error) {
      console.error("Error During Sending Message", error);
      toast.error(error);
      throw error;
    }
  }
  const handlecallbutton = (e) => {
    e.preventDefault();
    const numberTo = "+917870087169";
    window.open(`tel:${numberTo}`, "_blank");
  }


  return (
    <>
        <div>
    <section className="text-gray-600 body-font bg-gradient-to-r from-blue-50 to-cyan-50">
      <div className="container mx-auto flex px-2 md:px-10 py-16 md:flex-row flex-col items-center ">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center p-5">
          <div className="text-blue-700/95 mb-5">
          India's First Customized Online Tiffin Service
          </div>
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
          Order a healthy and well-balanced meal. It’s all homemade… "Ghar ka khana just the way you want."
          </h1>
          <p className="mb-8 leading-relaxed">
            Eat Healthy and Be Healthy
          </p>
          <div className="flex justify-center relative w-auto p-7 px-5 md:px-20 flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">
            <form onSubmit={handleFormSubmit}>
              <div className="grid gap-6 mb-6 md:grid-cols-2">
                <div>
                  <label
                    htmlFor="first_name"
                    className="block mb-2 text-sm font-medium text-gray-900 light:text-white"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="first_name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 light:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 light:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Your Name"
                    required=""
                  />
                </div>
                <div>
                  <label
                    htmlFor="phone"
                    className="block mb-2 text-sm font-medium text-gray-900 light:text-white"
                  >
                    Phone number
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 light:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 light:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Your Number"
                    required=""
                  />
                </div>
              </div>
              <button
                type="submit"
                className="bg-slate-800 font-semibold py-2 sm:w-full w-full  text-white rounded-md"
              >
                Get a Call back &gt;
              </button>
              {/* <a href="tel:+919155307250" target="_blank" rel="noreferal"> */}
              <button
              onClick={handlecallbutton}
                className="bg-slate-800 font-semibold py-2 sm:w-full w-full  text-white rounded-md mt-5"
              >
                Call for Book Tiffin
              </button>
              {/* </a> */}
            </form>
          </div>
        </div>
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-10/6">
          {images.map((image, index) => (
            <img
              key={index}
              className={`object-cover object-center rounded ${index === currentImageIndex ? "block" : "hidden"
                }`}
              alt="hero"
              src={image}
            />
          ))}
        </div>
      </div>
    </section>
  </div>
    </>
  )
}

export default ContactForm